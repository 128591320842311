import en from './../lang/en.json';

export function getEnObject() {
  return JSON.parse(JSON.stringify(en));
}

export function getI18nConfig() {
  return {
    legacy: true,
    locale: 'en',
    warnHtmlMessage: false,
    messages: {
      en: getEnObject(),
    }
  }
}

export default {
  legacy: true,
  locale: 'en',
  warnHtmlMessage: false,
  messages: {
    en: getEnObject(),
  }
};
